[type='text'],input:where(:not([type])),[type='email'],[type='url'],[type='password'],[type='number'],[type='date'],[type='datetime-local'],[type='month'],[type='search'],[type='tel'],[type='time'],[type='week'],[multiple],textarea,select {
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}

[type='text']:focus, input:where(:not([type])):focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #6b7280;
  opacity: 1;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #6b7280;
  opacity: 1;
}

input::placeholder,textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
  text-align: inherit;
}

::-webkit-datetime-edit {
  display: -webkit-inline-flex;
  display: inline-flex;
}

::-webkit-datetime-edit,::-webkit-datetime-edit-year-field,::-webkit-datetime-edit-month-field,::-webkit-datetime-edit-day-field,::-webkit-datetime-edit-hour-field,::-webkit-datetime-edit-minute-field,::-webkit-datetime-edit-second-field,::-webkit-datetime-edit-millisecond-field,::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  print-color-adjust: exact;
}

[multiple],[size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  print-color-adjust: unset;
}

[type='checkbox'],[type='radio'] {
  -webkit-appearance: none;
          appearance: none;
  padding: 0;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}

[type='checkbox'] {
  border-radius: 0px;
}

[type='radio'] {
  border-radius: 100%;
}

[type='checkbox']:focus,[type='radio']:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

[type='checkbox']:checked,[type='radio']:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type='checkbox']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active)  {
  [type='checkbox']:checked {
    -webkit-appearance: auto;
            appearance: auto;
  }
}

[type='radio']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active)  {
  [type='radio']:checked {
    -webkit-appearance: auto;
            appearance: auto;
  }
}

[type='checkbox']:checked:hover,[type='checkbox']:checked:focus,[type='radio']:checked:hover,[type='radio']:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}

[type='checkbox']:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

@media (forced-colors: active)  {
  [type='checkbox']:indeterminate {
    -webkit-appearance: auto;
            appearance: auto;
  }
}

[type='checkbox']:indeterminate:hover,[type='checkbox']:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}

[type='file'] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit;
}

[type='file']:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}

.visible {
  visibility: visible !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.inset-x-0 {
  left: 0px !important;
  right: 0px !important;
}

.bottom-0 {
  bottom: 0px !important;
}

.left-0 {
  left: 0px !important;
}

.left-\[-5px\] {
  left: -5px !important;
}

.left-\[20px\] {
  left: 20px !important;
}

.left-\[471px\] {
  left: 471px !important;
}

.left-\[64px\] {
  left: 64px !important;
}

.left-\[8\.11px\] {
  left: 8.11px !important;
}

.left-\[9\.51px\] {
  left: 9.51px !important;
}

.top-0 {
  top: 0px !important;
}

.top-\[-7px\] {
  top: -7px !important;
}

.top-\[-90px\] {
  top: -90px !important;
}

.top-\[10\.49px\] {
  top: 10.49px !important;
}

.top-\[186px\] {
  top: 186px !important;
}

.top-\[22px\] {
  top: 22px !important;
}

.top-\[260px\] {
  top: 260px !important;
}

.top-\[8\.12px\] {
  top: 8.12px !important;
}

.isolate {
  isolation: isolate !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-50 {
  z-index: 50 !important;
}

.col-start-2 {
  grid-column-start: 2 !important;
}

.row-span-2 {
  grid-row: span 2 / span 2 !important;
}

.-m-1 {
  margin: -0.25rem !important;
}

.-m-1\.5 {
  margin: -0.375rem !important;
}

.-m-2 {
  margin: -0.5rem !important;
}

.-m-2\.5 {
  margin: -0.625rem !important;
}

.mx-20 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mr-\[28px\] {
  margin-right: 28px !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-\[120px\] {
  margin-top: 120px !important;
}

.mt-\[12px\] {
  margin-top: 12px !important;
}

.mt-\[15px\] {
  margin-top: 15px !important;
}

.mt-\[28px\] {
  margin-top: 28px !important;
}

.mt-\[35px\] {
  margin-top: 35px !important;
}

.mt-\[45px\] {
  margin-top: 45px !important;
}

.mt-\[5px\] {
  margin-top: 5px !important;
}

.mt-\[65px\] {
  margin-top: 65px !important;
}

.mt-\[84px\] {
  margin-top: 84px !important;
}

.mt-\[9px\] {
  margin-top: 9px !important;
}

.line-clamp-3 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 3 !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.flex {
  display: -webkit-flex !important;
  display: flex !important;
}

.inline-flex {
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
}

.table {
  display: table !important;
}

.grid {
  display: grid !important;
}

.contents {
  display: contents !important;
}

.hidden {
  display: none !important;
}

.h-10 {
  height: 2.5rem !important;
}

.h-5 {
  height: 1.25rem !important;
}

.h-6 {
  height: 1.5rem !important;
}

.h-64 {
  height: 16rem !important;
}

.h-8 {
  height: 2rem !important;
}

.h-\[100px\] {
  height: 100px !important;
}

.h-\[10px\] {
  height: 10px !important;
}

.h-\[15px\] {
  height: 15px !important;
}

.h-\[18px\] {
  height: 18px !important;
}

.h-\[20px\] {
  height: 20px !important;
}

.h-\[21px\] {
  height: 21px !important;
}

.h-\[22px\] {
  height: 22px !important;
}

.h-\[247\.23px\] {
  height: 247.23px !important;
}

.h-\[24px\] {
  height: 24px !important;
}

.h-\[264px\] {
  height: 264px !important;
}

.h-\[319\.59px\] {
  height: 319.59px !important;
}

.h-\[328px\] {
  height: 328px !important;
}

.h-\[341\.88px\] {
  height: 341.88px !important;
}

.h-\[341px\] {
  height: 341px !important;
}

.h-\[40px\] {
  height: 40px !important;
}

.h-\[457px\] {
  height: 457px !important;
}

.h-\[45px\] {
  height: 45px !important;
}

.h-\[488px\] {
  height: 488px !important;
}

.h-\[502px\] {
  height: 502px !important;
}

.h-\[50px\] {
  height: 50px !important;
}

.h-\[56px\] {
  height: 56px !important;
}

.h-\[585px\] {
  height: 585px !important;
}

.h-\[9px\] {
  height: 9px !important;
}

.h-full {
  height: 100% !important;
}

.h-px {
  height: 1px !important;
}

.h-screen {
  height: 100vh !important;
}

.min-h-12 {
  min-height: 3rem !important;
}

.min-h-\[311px\] {
  min-height: 311px !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

.w-10 {
  width: 2.5rem !important;
}

.w-5 {
  width: 1.25rem !important;
}

.w-5\/6 {
  width: 83.333333% !important;
}

.w-6 {
  width: 1.5rem !important;
}

.w-\[100px\] {
  width: 100px !important;
}

.w-\[10px\] {
  width: 10px !important;
}

.w-\[14px\] {
  width: 14px !important;
}

.w-\[17px\] {
  width: 17px !important;
}

.w-\[20px\] {
  width: 20px !important;
}

.w-\[21px\] {
  width: 21px !important;
}

.w-\[22px\] {
  width: 22px !important;
}

.w-\[24px\] {
  width: 24px !important;
}

.w-\[381\.98px\] {
  width: 381.98px !important;
}

.w-\[397px\] {
  width: 397px !important;
}

.w-\[401px\] {
  width: 401px !important;
}

.w-\[40px\] {
  width: 40px !important;
}

.w-\[412\.77px\] {
  width: 412.77px !important;
}

.w-\[429px\] {
  width: 429px !important;
}

.w-\[45px\] {
  width: 45px !important;
}

.w-\[56px\] {
  width: 56px !important;
}

.w-\[763px\] {
  width: 763px !important;
}

.w-\[793px\] {
  width: 793px !important;
}

.w-\[803px\] {
  width: 803px !important;
}

.w-auto {
  width: auto !important;
}

.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw !important;
}

.min-w-0 {
  min-width: 0px !important;
}

.min-w-full {
  min-width: 100% !important;
}

.max-w-2xl {
  max-width: 42rem !important;
}

.max-w-\[1464px\] {
  max-width: 1464px !important;
}

.max-w-\[1700px\] {
  max-width: 1700px !important;
}

.max-w-\[308px\] {
  max-width: 308px !important;
}

.max-w-\[419\.33px\] {
  max-width: 419.33px !important;
}

.max-w-lg {
  max-width: 32rem !important;
}

.max-w-xl {
  max-width: 36rem !important;
}

.max-w-xs {
  max-width: 20rem !important;
}

.flex-1 {
  -webkit-flex: 1 1 0% !important;
          flex: 1 1 0% !important;
}

.flex-none {
  -webkit-flex: none !important;
          flex: none !important;
}

.shrink {
  -webkit-flex-shrink: 1 !important;
          flex-shrink: 1 !important;
}

.shrink-0 {
  -webkit-flex-shrink: 0 !important;
          flex-shrink: 0 !important;
}

.grow-0 {
  -webkit-flex-grow: 0 !important;
          flex-grow: 0 !important;
}

.basis-2\/4 {
  -webkit-flex-basis: 50% !important;
          flex-basis: 50% !important;
}

.border-collapse {
  border-collapse: collapse !important;
}

.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.list-inside {
  list-style-position: inside !important;
}

.list-none {
  list-style-type: none !important;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.flex-row-reverse {
  -webkit-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}

.flex-col {
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
}

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
}

.items-start {
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important;
}

.items-center {
  -webkit-align-items: center !important;
          align-items: center !important;
}

.justify-start {
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
}

.justify-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.justify-center {
  -webkit-justify-content: center !important;
          justify-content: center !important;
}

.justify-between {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.gap-12 {
  gap: 3rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-2\.5 {
  gap: 0.625rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-\[10px\] {
  gap: 10px !important;
}

.gap-\[11px\] {
  gap: 11px !important;
}

.gap-\[13px\] {
  gap: 13px !important;
}

.gap-\[15px\] {
  gap: 15px !important;
}

.gap-\[18px\] {
  gap: 18px !important;
}

.gap-\[19px\] {
  gap: 19px !important;
}

.gap-\[24px\] {
  gap: 24px !important;
}

.gap-\[29px\] {
  gap: 29px !important;
}

.gap-\[42px\] {
  gap: 42px !important;
}

.gap-\[50px\] {
  gap: 50px !important;
}

.gap-\[71px\] {
  gap: 71px !important;
}

.gap-\[8\.59px\] {
  gap: 8.59px !important;
}

.gap-\[90px\] {
  gap: 90px !important;
}

.gap-x-2 {
  -webkit-column-gap: 0.5rem !important;
          column-gap: 0.5rem !important;
}

.gap-x-8 {
  -webkit-column-gap: 2rem !important;
          column-gap: 2rem !important;
}

.gap-y-\[50px\] {
  row-gap: 50px !important;
}

.space-y-\[10px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(10px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(10px * var(--tw-space-y-reverse)) !important;
}

.self-center {
  -webkit-align-self: center !important;
          align-self: center !important;
}

.self-stretch {
  -webkit-align-self: stretch !important;
          align-self: stretch !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-x-clip {
  overflow-x: clip !important;
}

.rounded-3xl {
  border-radius: 1.5rem !important;
}

.rounded-\[10\.01px\] {
  border-radius: 10.01px !important;
}

.rounded-\[10\.82px\] {
  border-radius: 10.82px !important;
}

.rounded-\[10px\] {
  border-radius: 10px !important;
}

.rounded-\[15px\] {
  border-radius: 15px !important;
}

.rounded-\[20px\] {
  border-radius: 20px !important;
}

.rounded-\[25px\] {
  border-radius: 25px !important;
}

.rounded-\[5\.01px\] {
  border-radius: 5.01px !important;
}

.rounded-\[90px\] {
  border-radius: 90px !important;
}

.rounded-full {
  border-radius: 9999px !important;
}

.rounded-md {
  border-radius: 0.375rem !important;
}

.rounded-tl-lg {
  border-top-left-radius: 0.5rem !important;
}

.rounded-tr-lg {
  border-top-right-radius: 0.5rem !important;
}

.border {
  border-width: 1px !important;
}

.border-0 {
  border-width: 0px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-t {
  border-top-width: 1px !important;
}

.border-\[\#009EF7\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(0 158 247 / var(--tw-border-opacity)) !important;
}

.border-black {
  --tw-border-opacity: 1 !important;
  border-color: rgb(0 0 0 / var(--tw-border-opacity)) !important;
}

.border-gray-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}

.border-indigo-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(165 180 252 / var(--tw-border-opacity)) !important;
}

.border-sky-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(14 165 233 / var(--tw-border-opacity)) !important;
}

.border-stone-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(214 211 209 / var(--tw-border-opacity)) !important;
}

.border-opacity-20 {
  --tw-border-opacity: 0.2 !important;
}

.border-opacity-5 {
  --tw-border-opacity: 0.05 !important;
}

.bg-\[\#1D1D25\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(29 29 37 / var(--tw-bg-opacity)) !important;
}

.bg-\[\#F8FCFF\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 252 255 / var(--tw-bg-opacity)) !important;
}

.bg-blue-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
}

.bg-sky-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity)) !important;
}

.bg-slate-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity)) !important;
}

.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.bg-zinc-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(161 161 170 / var(--tw-bg-opacity)) !important;
}

.bg-opacity-5 {
  --tw-bg-opacity: 0.05 !important;
}

.bg-opacity-90 {
  --tw-bg-opacity: 0.9 !important;
}

.bg-\[url\(\'assets\/img\/Background\.svg\'\)\] {
  background-image: url('assets/img/Background.svg') !important;
}

.bg-right-top {
  background-position: right top !important;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.fill-current {
  fill: currentColor !important;
}

.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-1\.5 {
  padding: 0.375rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-2\.5 {
  padding: 0.625rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-\[15px\] {
  padding: 15px !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-\[25px\] {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.px-\[46px\] {
  padding-left: 46px !important;
  padding-right: 46px !important;
}

.px-px {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.tw-py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-2\.5 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.tw-py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-3\.5 {
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important;
}

.tw-py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-\[35px\] {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.pb-\[0\.88px\] {
  padding-bottom: 0.88px !important;
}

.pb-\[81px\] {
  padding-bottom: 81px !important;
}

.pb-\[83px\] {
  padding-bottom: 83px !important;
}

.pt-14 {
  padding-top: 3.5rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.text-center {
  text-align: center !important;
}

.font-\[\'Inter\'\] {
  font-family: 'Inter' !important;
}

.font-\[\'Montserrat\'\] {
  font-family: 'Montserrat' !important;
}

.font-\[\'Nunito_Sans\'\] {
  font-family: 'Nunito Sans' !important;
}

.text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}

.text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.text-\[15px\] {
  font-size: 15px !important;
}

.text-\[19px\] {
  font-size: 19px !important;
}

.text-\[25px\] {
  font-size: 25px !important;
}

.text-\[35px\] {
  font-size: 35px !important;
}

.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.leading-4 {
  line-height: 1rem !important;
}

.leading-5 {
  line-height: 1.25rem !important;
}

.leading-6 {
  line-height: 1.5rem !important;
}

.leading-7 {
  line-height: 1.75rem !important;
}

.leading-8 {
  line-height: 2rem !important;
}

.leading-9 {
  line-height: 2.25rem !important;
}

.leading-\[25\.20px\] {
  line-height: 25.20px !important;
}

.leading-\[25px\] {
  line-height: 25px !important;
}

.leading-\[30px\] {
  line-height: 30px !important;
}

.leading-\[43\.05px\] {
  line-height: 43.05px !important;
}

.leading-loose {
  line-height: 2 !important;
}

.leading-normal {
  line-height: 1.5 !important;
}

.leading-relaxed {
  line-height: 1.625 !important;
}

.leading-snug {
  line-height: 1.375 !important;
}

.leading-tight {
  line-height: 1.25 !important;
}

.tracking-tight {
  letter-spacing: -0.025em !important;
}

.text-\[\#1D1D25\] {
  --tw-text-opacity: 1 !important;
  color: rgb(29 29 37 / var(--tw-text-opacity)) !important;
}

.text-\[\#20202D\] {
  --tw-text-opacity: 1 !important;
  color: rgb(32 32 45 / var(--tw-text-opacity)) !important;
}

.text-blue-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(23 37 84 / var(--tw-text-opacity)) !important;
}

.text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}

.text-gray-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(75 85 99 / var(--tw-text-opacity)) !important;
}

.text-gray-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(55 65 81 / var(--tw-text-opacity)) !important;
}

.text-gray-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(17 24 39 / var(--tw-text-opacity)) !important;
}

.text-sky-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(56 189 248 / var(--tw-text-opacity)) !important;
}

.text-sky-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(14 165 233 / var(--tw-text-opacity)) !important;
}

.text-slate-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(100 116 139 / var(--tw-text-opacity)) !important;
}

.text-slate-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(30 41 59 / var(--tw-text-opacity)) !important;
}

.text-slate-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(15 23 42 / var(--tw-text-opacity)) !important;
}

.text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-zinc-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(24 24 27 / var(--tw-text-opacity)) !important;
}

.opacity-80 {
  opacity: 0.8 !important;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.backdrop-blur-\[25px\] {
  --tw-backdrop-blur: blur(25px) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.backdrop-blur-\[31px\] {
  --tw-backdrop-blur: blur(31px) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.placeholder\:font-\[\'Inter\'\]::-webkit-input-placeholder {
  font-family: 'Inter' !important;
}

.placeholder\:font-\[\'Inter\'\]:-ms-input-placeholder {
  font-family: 'Inter' !important;
}

.placeholder\:font-\[\'Inter\'\]::placeholder {
  font-family: 'Inter' !important;
}

.placeholder\:text-base::-webkit-input-placeholder {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.placeholder\:text-base:-ms-input-placeholder {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.placeholder\:text-base::placeholder {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.placeholder\:font-medium::-webkit-input-placeholder {
  font-weight: 500 !important;
}

.placeholder\:font-medium:-ms-input-placeholder {
  font-weight: 500 !important;
}

.placeholder\:font-medium::placeholder {
  font-weight: 500 !important;
}

.placeholder\:leading-relaxed::-webkit-input-placeholder {
  line-height: 1.625 !important;
}

.placeholder\:leading-relaxed:-ms-input-placeholder {
  line-height: 1.625 !important;
}

.placeholder\:leading-relaxed::placeholder {
  line-height: 1.625 !important;
}

.placeholder\:text-gray-600::-webkit-input-placeholder {
  --tw-text-opacity: 1 !important;
  color: rgb(75 85 99 / var(--tw-text-opacity)) !important;
}

.placeholder\:text-gray-600:-ms-input-placeholder {
  --tw-text-opacity: 1 !important;
  color: rgb(75 85 99 / var(--tw-text-opacity)) !important;
}

.placeholder\:text-gray-600::placeholder {
  --tw-text-opacity: 1 !important;
  color: rgb(75 85 99 / var(--tw-text-opacity)) !important;
}

.placeholder\:opacity-70::-webkit-input-placeholder {
  opacity: 0.7 !important;
}

.placeholder\:opacity-70:-ms-input-placeholder {
  opacity: 0.7 !important;
}

.placeholder\:opacity-70::placeholder {
  opacity: 0.7 !important;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.peer:focus ~ .peer-focus\:border-t-2 {
  border-top-width: 2px !important;
}

.peer:focus ~ .peer-focus\:border-sky-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(14 165 233 / var(--tw-border-opacity)) !important;
}

@media (min-width: 640px) {
  .sm\:order-1 {
    -webkit-order: 1 !important;
            order: 1 !important;
  }

  .sm\:order-2 {
    -webkit-order: 2 !important;
            order: 2 !important;
  }

  .sm\:mx-\[103px\] {
    margin-left: 103px !important;
    margin-right: 103px !important;
  }

  .sm\:mt-0 {
    margin-top: 0px !important;
  }

  .sm\:flex {
    display: -webkit-flex !important;
    display: flex !important;
  }

  .sm\:w-\[173px\] {
    width: 173px !important;
  }

  .sm\:w-fit {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }

  .sm\:w-full {
    width: 100% !important;
  }

  .sm\:min-w-\[523px\] {
    min-width: 523px !important;
  }

  .sm\:max-w-\[240px\] {
    max-width: 245px !important;
  }

  .sm\:items-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }

  .sm\:justify-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }

  .sm\:gap-2 {
    gap: 0.5rem !important;
  }

  .sm\:text-center {
    text-align: center !important;
  }

  .sm\:text-6xl {
    font-size: 3.75rem !important;
    line-height: 1 !important;
  }

  .sm\:text-sm {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }
}

@media (min-width: 768px) {
  .md\:max-w-md {
    max-width: 28rem !important;
  }

  .md\:gap-4 {
    gap: 1rem !important;
  }
}

@media (min-width: 1024px) {
  .lg\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .lg\:mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .lg\:ml-\[46px\] {
    margin-left: 46px !important;
  }

  .lg\:mr-\[-46px\] {
    margin-right: -46px !important;
  }

  .lg\:mt-0 {
    margin-top: 0px !important;
  }

  .lg\:mt-\[35px\] {
    margin-top: 35px !important;
  }

  .lg\:block {
    display: block !important;
  }

  .lg\:flex {
    display: -webkit-flex !important;
    display: flex !important;
  }

  .lg\:grid {
    display: grid !important;
  }

  .lg\:hidden {
    display: none !important;
  }

  .lg\:max-w-\[365px\] {
    max-width: 365px !important;
  }

  .lg\:max-w-none {
    max-width: none !important;
  }

  .lg\:flex-1 {
    -webkit-flex: 1 1 0% !important;
            flex: 1 1 0% !important;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .lg\:flex-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }

  .lg\:items-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }

  .lg\:gap-10 {
    gap: 2.5rem !important;
  }

  .lg\:gap-6 {
    gap: 1.5rem !important;
  }

  .lg\:gap-x-0 {
    -webkit-column-gap: 0px !important;
            column-gap: 0px !important;
  }

  .lg\:gap-x-12 {
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }

  .lg\:gap-x-2 {
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }

  .lg\:gap-y-6 {
    row-gap: 1.5rem !important;
  }

  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .lg\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .lg\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .lg\:py-\[101px\] {
    padding-top: 101px !important;
    padding-bottom: 101px !important;
  }

  .lg\:py-\[107px\] {
    padding-top: 107px !important;
    padding-bottom: 107px !important;
  }

  .lg\:text-\[15px\] {
    font-size: 15px !important;
  }

  .lg\:text-base {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .lg\:leading-6 {
    line-height: 1.5rem !important;
  }

  .lg\:leading-7 {
    line-height: 1.75rem !important;
  }

  .lg\:leading-\[43\.05px\] {
    line-height: 43.05px !important;
  }

  .lg\:leading-normal {
    line-height: 1.5 !important;
  }

  .lg\:leading-snug {
    line-height: 1.375 !important;
  }
}

@media (min-width: 1280px) {
  .xl\:col-auto {
    grid-column: auto !important;
  }

  .xl\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .xl\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .xl\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .xl\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .xl\:mt-0 {
    margin-top: 0px !important;
  }

  .xl\:mt-\[-40px\] {
    margin-top: -40px !important;
  }

  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .xl\:gap-x-0 {
    -webkit-column-gap: 0px !important;
            column-gap: 0px !important;
  }

  .xl\:leading-\[25px\] {
    line-height: 25px !important;
  }

  .xl\:leading-\[58\.37px\] {
    line-height: 58.37px !important;
  }
}
ul.list_style_circle {
  list-style: circle !important;
  list-style-position: inside !important;
}


.tw-transition {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.tw-duration-500 {
  transition-duration: 500ms !important;
}

.tw-ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}


.hover\:scale-105:hover {
  --tw-scale-x: 1.05 !important;
  --tw-scale-y: 1.05 !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.hover\:border-none:hover {
  border-style: none !important;
}

.hover\:bg-\[\#1D1D25\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(29 29 37 / var(--tw-bg-opacity)) !important;
}

.hover\:text-white:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.tw-order-1 {
  -webkit-order: 1 !important;
          order: 1 !important;
}

.tw-order-2 {
  -webkit-order: 2 !important;
          order: 2 !important;
}

@media (min-width: 768px) {
  
  .md\:order-1 {
    -webkit-order: 1 !important;
            order: 1 !important;
  }

  .md\:order-2 {
    -webkit-order: 2 !important;
            order: 2 !important;
  }

  .md\:max-w-full {
    max-width: 100% !important;
  }

  

}

@media (min-width: 1024px) {

  .lg\:max-w-fit {
    max-width: -webkit-fit-content !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}


